.list {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .totals {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    h2 {
        margin-bottom: 20px;
        text-align: center;
    }

    .transaction-table {
        width: 80%;
        border-collapse: collapse;
        margin: 0 auto; // Center the table horizontally

        th, td {
            border: 1px solid #ddd;
            padding: 8px;
            text-align: center; // Center text in table cells
        }

        th {
            background-color: #f4f4f4;
            font-weight: bold;
        }

        td {
            input, select {
                width: 95%;
                padding: 5px;
                border: 1px solid #ddd;
                border-radius: 4px;
                text-align: center; // Center text in input/select elements
            }

            button {
                padding: 5px 10px;
                margin: 0 5px;
                border: none;
                border-radius: 4px;
                cursor: pointer;
                font-weight: bold;
                text-align: center; // Center text in button

                &:first-of-type {
                    background-color: #4CAF50;
                    color: #fff;
                }

                &:last-of-type {
                    background-color: #f44336;
                    color: #fff;
                }

                &:hover {
                    opacity: 0.9;
                }
            }
        }
    }
}