$form-background-color: #ffffff;
$form-border-color: #e0e0e0;
$form-padding: 20px;
$form-border-radius: 10px;
$input-border-radius: 5px;
$input-border-color: #cccccc;
$input-padding: 10px;
$input-margin-bottom: 15px;
$button-background-color: #4CAF50;
$button-color: #ffffff;
$button-hover-color: darken($button-background-color, 10%);
$box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
$transition-duration: 0.3s;

.form {
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: $form-background-color;
    border: 1px solid $form-border-color;
    border-radius: $form-border-radius;
    padding: $form-padding;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    box-shadow: $box-shadow;
    transition: transform $transition-duration;

    &:hover {
        transform: translateY(-5px);
    }

    h2 {
        text-align: center;
        color: #333;
        margin-bottom: 20px;
        font-size: 1.8rem;
    }

    input[type="number"],
    input[type="date"],
    input[type="text"],
    select {
        width: 95%;
        padding: $input-padding;
        margin-bottom: $input-margin-bottom;
        border: 1px solid $input-border-color;
        border-radius: $input-border-radius;
        font-size: 1rem;
        transition: border-color $transition-duration;

        &:focus {
            border-color: $button-background-color;
            outline: none;
        }
    }
    select {
        width: 100%;
    }

    button {
        width: 100%;
        padding: $input-padding;
        background-color: $button-background-color;
        color: $button-color;
        border: none;
        border-radius: $input-border-radius;
        font-size: 1rem;
        cursor: pointer;
        transition: background-color $transition-duration;

        &:hover {
            background-color: $button-hover-color;
        }
    }
}